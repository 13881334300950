<template>
    <div class="Index">
        <navBar></navBar>
        <router-view />
        <foot-bar class="mar-t-80"></foot-bar>
    </div>
</template>

<script>
 import navBar from "@/components/navBar"
 import footBar from "@/components/footBar"
export default {
  name: 'Index',
  components:{
      navBar,
      footBar
  },
  data(){
      return{
          
      }
  },
}
</script>
<style lang="scss" >
</style>
