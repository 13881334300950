<template>
    <div class="navBar">
        <div class="content flex fl-ai-c">
            <div class="logo-box fl1">
                <img src="@/assets/img/logo.png" />
            </div>
            <el-menu
                :default-active="activeIndex"
                class="nav-box"
                mode="horizontal"
                router
            >
                <el-menu-item
                    v-for="(item, idx) in menuList"
                    :index="'./' + item.link"
                    :key="idx"
                    >{{ item.title }}</el-menu-item
                >
            </el-menu>
        </div>
    </div>
</template>
<script>
 import {ScrollTop} from '@/utils/util'
 
export default {
  data(){
      return{
          activeIndex:"./Home",
          menuList:[
              {title:'首页',link:'Home'},
              {title:'公司业务',link:'Business'},
              {title:'产品服务',link:'Proservice'},
              {title:'热点资讯',link:'Hotnews'},
              {title:'人才招募',link:'Talent'},
              {title:'成为合伙人',link:'Partnerv'},
              {title:'联系我们',link:'Aboutme'},
          ]
      }
  },
  created(){
      this.activeIndex ='./'+ this.$route.name;
  },
  watch:{
      $route(){
           this.activeIndex ='./'+ this.$route.name;
           ScrollTop()
      }
  },
  components: {
  }
}
</script>
<style lang="scss" >
.navBar {
    background-color: #fff;
    .nav-box {
        border: 0;

        .el-menu-item {
            border-bottom: 0;
            font-size: 16px;

            font-weight: bold;
            color: #002062;

            &.is-active {
                color: #4898ff;
            }
        }
    }
    .logo-box > img {
        width: 220px;
        height: 66px;
    }
    .content {
        height: 88px;
    }
}
</style>
