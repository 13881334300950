<template>
    <div class="footBar">
        <div class="content">
            <div class="flex fl-ai-c logo-box">
                <div class="fl1">
                    <img src="@/assets/img/logo.png" width="200px" />
                </div>
                <div class="link-box flex">
                    <div
                        class="po-r"
                        v-for="(item, idx) in menuList"
                        :key="idx"
                    >
                        <router-link :to="'./' + item.link">
                            {{ item.title }}</router-link
                        >
                    </div>
                </div>
            </div>
            <div class="tel-box flex fl-ai-c">
                <div class="fl1">
                    <img src="@/assets/img/home-tel.png" width="178px" />
                    <p class="mar-t-6">
                        公司地址：北京市朝阳区西大望路甲23号合生创展大楼
                    </p>
                </div>
                <div class="qr-con t-al-c">
                    <img src="@/assets/img/qrcode-pubilc.png" />
                    <p>官方公众号</p>
                </div>
            </div>
        </div>
        <div class="num-box t-al-c"> 
            <p>
                <span>Copyright2017-2020© 优选好生活科技(珠海)有限公司北京分公司 京ICP备17074361号-1</span>
                <a target="_blank" href="https://beian.miit.gov.cn" style="color: #002062">
                    <img src="@/assets/img/beian.png" width="18" height="18" style="margin-left: 10px" alt="">
                    京公网安备 11010502045783号
                </a>
            </p>
        </div>
    </div>
</template>
<script>
 
export default {
  data(){
      return{
           menuList:[
              {title:'首页',link:'Home'},
              {title:'公司业务',link:'Business'},
              {title:'产品服务',link:'Proservice'},
              {title:'热点资讯',link:'Hotnews'},
              {title:'人才招募',link:'Talent'},
              {title:'成为合伙人',link:'Partnerv'},
              {title:'联系我们',link:'Aboutme'},
          ]
      }
  },
  components: {
  }
}
</script>
<style lang="scss" scoped>
.footBar {
    font-size: 14px;
    font-weight: 400;
    color: #002062;
    line-height: 20px;
    background: RGBA(250, 253, 255, 1);
    min-width: 1200px;
}
.num-box {
    height: 80px;
    padding: 27px 0 34px 0;
    margin-top:12px;
    letter-spacing: 1px;
    background: rgba(72, 152, 255, 0.06);
}
.logo-box {
    padding: 44px 0;
}
.link-box {
    width: 679px;

    > div {
       // width: 200px;
        text-align: right;
           margin-left: 42px;

        a {
            font-size: 14px;
            font-weight: bold;
            color: #002062;
            line-height: 20px;
        }

        &:last-child::before {
            width: 0;
        }
        &::before {
            content: "";
            position: absolute;
            height: 15px;
            width: 1px;
            background: #002062;
            right: -20px;
            top: 3px;
        }
    }
}
.qr-con {
    > img {
        width: 90px;
        border: 1px solid #4898ff;
    }
    > p {
        font-size: 14px;
        font-weight: 400;
        color: #6679a1;
        line-height: 20px;
        margin-top: 4px;
    }
}
.mar-t-6 {
    margin-top: 6px;
}

</style>
